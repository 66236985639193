<template>
  <div class="bgbox" :class="postionVal == 0.1 ? 'hovers' : 'nohovers'">
    <div class="script entrance" @click="backtrack">驾驶舱</div>
    <div class="bibox" :style="{ left: postionVal + 'rem' }">
      <div class="rLeft">
        <div class="Putaway" @click="Putaway()">
          <i
            :class="
              postionVal == 0.1 ? 'el-icon-caret-left' : 'el-icon-caret-right'
            "
          ></i>
        </div>
      </div>
      <div class="chart-box">
        <div class="chart-item pipe">
          <div class="chart-title">
            <img :src="title" />
            <p class="titleContext titleContext__between">管网概况</p>
          </div>
          <div class="rightbar pipe_box">
            <div class="pipeBox">
              <div class="pipeBox_img">
                <img class="pipe_img" src="@/assets/br/guanwa.png" />
              </div>
              <div>
                <div class="pipeBox_number">{{general.totalLength}}</div>
                <div class="pipeBox_text">管网总长(KM)</div>
              </div>
            </div>
            <div class="pipeBox">
              <div class="pipeBox_img">
                <img class="pipe_img" src="@/assets/br/guanwa2.png" />
              </div>
              <div>
                <div class="pipeBox_number">{{general.stationKindValue}}</div>
                <div class="pipeBox_text">调压箱(柜)</div>
              </div>
            </div>
            <div class="pipeBox">
              <div class="pipeBox_img">
                <img class="pipe_img" src="@/assets/br/guanwa3.png" />
              </div>
              <div>
                <div class="pipeBox_number">{{general.pipeFittingValue}}</div>
                <div class="pipeBox_text">阀门井</div>
              </div>
            </div>
            <div class="pipeBox">
              <div class="pipeBox_img">
                <img class="pipe_img" src="@/assets/br/guanwa4.png" />
              </div>
              <div>
                <div class="pipeBox_number">{{general.stationCount}}</div>
                <div class="pipeBox_text">供气站</div>
              </div>
            </div>
          </div>
        </div>
        <div class="chart-item">
          <div class="chart-title">
            <img :src="title" />
            <p class="titleContext titleContext__between">近30天设备巡检情况</p>
          </div>
          <div class="rightbar">
            <scroll-table
              :data="bar1Data"
              :head="bar1Head"
              :rate="1000"
            >
              <template #link="{row, col}">
                <div class="link-block" :style="{color: col.prop == 'doneNum' ? '#32FFAF' : (col.prop == 'exceptionNum' ? '#FF2C2C' : '#ffffff')}">{{ row[col.prop] }}</div>
              </template>
            </scroll-table>
          </div>
        </div>
        <div class="chart-item">
          <div class="chart-title">
            <img :src="title" />
            <p class="titleContext titleContext__between">当日管线巡检情况</p>
          </div>
          <div class="rightbar pp1" ref="leftbar3">
            <div class="slide-container">
              <div
                class="polling slide-item"
                v-for="(item, index) in pollingList"
                :key="index"
                @mouseenter="onmouseover"
                @mouseleave="onmouseout"
              >
                <div class="polling_info">
                  <div class="person_info">
                    <div class="person_name">{{ item.patrolerName }}</div>
                    <img class="person_img" src="@/assets/br/schedule.png" />
                  </div>
                  <div class="person_num">{{ item.percentage }}%</div>
                </div>
                <div class="schedule">
                  <div class="schedule_box">
                    <div
                      :class="['schedule_fill', {one: cWidth}] "
                      :style="{ width: (item.percentage * 0.036) + 'rem' }"
                    ></div>
                    <img
                     
                      :class="['schedule_img', {two: cWidth}] "
                      v-if="item.percentage != 0"
                      src="@/assets/br/scheduledot.png"
                      :style="{ left: item.percentage * 0.036 - 0.11 + 'rem' }"
                    />
                     <!-- class="schedule_img" -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import title from "@/assets/br/chart-title.png";
import scrollTable from "../scrollTable";
import { equipmentData, lineData } from "@/RequestPort/bi/index";
import { getPipeOverview } from "@/views/Gis/apis/gis";
export default {
  name: "MapLine",
  components: { scrollTable },
  props: {
    layerSelect: [],
    type: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    title,
    postionVal: 0.1,
    // 评分周期视图
    leftbar1: null,
    leftbar2: null,
    leftbar3: null,
    bar1Data: [],
    bar1Head: [
      { prop: "equipmentTypeName", label: "设备类型" },
      { prop: "patrolNum", label: "巡检点数" },
      { prop: "doneNum", label: "无异常", slotName: 'link'  },
      { prop: "exceptionNum", label: "异常数", slotName: 'link'  },
      { prop: "handleNum", label: "已处理" },
    ],
    pollingList: [],
    tableData2: [],
    general: {
      totalLength:'',
      stationKindValue:"",
      pipeFittingValue:'',
      stationCount:''
    },
    active: 0,
    timer2: null,
    cWidth: true
  }),
  computed: {
   
  },
  watch: {
    "$store.getters.fullState"(val) {
      if (val) {
        this.postionVal = 0.1;
      } else {
        this.postionVal = -4.2;
      }
    },
  },
  destroyed() {
    clearInterval(this.timer2);
  },
  mounted() {
    // this.setScroll()
    this.getPipeOverviews();
    this.getDatas()
    setTimeout( () => this.cWidth = false, 1500)
    // this.getEquipmentData();
    // this.getlineData();
  },
  methods: {
    onmouseover(){
      console.log(this.timer2)
      this.timer2 && clearInterval(this.timer2)
    },
    onmouseout(){
       this.setScroll("","",true)
    },
    backtrack() {
      //  this.$router.go(-1)
      this.$router.push("./index");
    },
    Putaway() {
      let state = this.$store.getters.fullState;
      this.$store.commit("gis/FullStateChange", !state);
    },
    getPipeOverviews() {
      getPipeOverview().then((res) => {
        if (res.code == 200) {
          this.general.totalLength = (res.data.pipeLineLengthVO.totalLength/1000).toFixed(2);
          // console.log(Number(res.data.stationCountVOList[1].stationKindList[1].value) , Number(res.data.stationCountVOList[1].stationKindList[2].value))
          this.general.stationKindValue = Number(res.data.stationCountVOList[1].stationKindList[1].value) + Number(res.data.stationCountVOList[1].stationKindList[2].value)
          this.general.pipeFittingValue = res.data.pipeFittingCountVO.pipeFittingList[0].value
          this.general.stationCount = res.data.stationCountVOList[0].stationCount
        }
      });
    },
    async getDatas(){
      let _this = this
      await _this.getlineData()
      await _this.getEquipmentData()
      await _this.setScroll(this.lineDatas,"pollingList",false)
    },
    async getlineData() {
      let data = {
        current: 1,
        size: 10000,
      };
      let that = this
      let res = await lineData(data)
      if (res.code == 200) {
        // res.data.list = [...res.data.list, ...res.data.list, ...res.data.list,...res.data.list]
        // this.pollingList = res.data.list
        // console.log(res.data)
        // that.$nextTick(() => {
          this.lineDatas = res.data
          // that.setScroll(res.data, "pollingList");
        // })
        
      }
    },
    async getEquipmentData() {
      let data = {
        current: 1,
        size: 10000,
      };
      let res = await equipmentData(data)
        if (res.code == 200) {
          // this.bar1Data = [...res.data.list, ...res.data.list, ...res.data.list,...res.data.list]
          this.bar1Data = res.data.list;
          // this.setScroll(res.data, "tableData2");
        }
    },
    setScroll(val, name,num) { 
      try {
        console.log(num,this.lineDatas)
        // if(!num){
        //   val = this.lineDatas
        //   name = "pollingList"
        // } 
        if (this.lineDatas.list.length < 6) return (this.pollingList = this.lineDatas.list);
        !num && (this.pollingList = [...this.lineDatas.list,...this.lineDatas.list])
        let _this = this;
        let body = this.$refs.leftbar3
        // console.log(body, '---------------');
        setTimeout(() => {
          _this.timer2 && clearInterval(_this.timer2)
          let winHeight = body.clientHeight
          let max = parseInt(winHeight / 50)
          let rowHeight = parseInt(winHeight / max)
          // console.log(winHeight, rowHeight, 'height');
          let els =  body.getElementsByClassName("polling")
          for(let el of els){
              el.style = `height: ${rowHeight}px !important; box-sizing: border-box;`
            };
          // let body = _this.$refs.leftbar3
          // let winHeight = document.getElementsByClassName("polling")[0].offsetHeight;
          // body.scrollTop = 0;
          _this.timer2 = setInterval(async () => {
            if (_this.active < this.lineDatas.list.length) {
              _this.sj(rowHeight,body)
              // this.active += 1;
              // for (let i = 0; i <= rowHeight; i++) {
              //   await _this.wait(0.5 / rowHeight);
              //   body.scrollTop = body.scrollTop + 1;
              //   if (body.scrollTop >= this.active * rowHeight) { 
              //     body.scrollTop = parseInt(this.active * rowHeight);
              //     break;
              //   }
              // }
            } else {
              body.scrollTop = 0;
              _this.active = 0;
              _this.sj(rowHeight,body)
            }
          }, 1500);
        }, 200);
      }catch(e){
        console.log(e, 'error');
      }
    },
    async sj(rowHeight,body){
      // console.log(rowHeight,body, 'sj---------');
    let _this = this;
      if (this.active < this.pollingList.length) {
        this.active += 1;
        for (let i = 0; i <= rowHeight; i++) {
          await _this.wait(0.5 / rowHeight);              
          body.scrollTop = Math.ceil(body.scrollTop + 1);
          if (body.scrollTop >= this.active * rowHeight) {
            body.scrollTop = parseInt(this.active * rowHeight);
            break;
          }
        }
      } else {
        body.scrollTop = 0;
        this.active = 0;
      }
    },
    wait(time = 0) {
      return new Promise((res, rej) => setTimeout(() => res(), time * 1000));
    },
  },
};
</script>
<style lang="scss">
.el-table::before{
  display: none !important;
}
</style>
<style lang="scss" scoped>
.script {
  position: fixed;
  top: 0.1rem;
  left: 0.1rem;
  // left: 0.43rem;
  font-size: 0.2rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #6eb4ff;
  cursor: pointer;
}
.entrance{
    width: 108px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    background: url('../../../assets/biIndex/entrance1.png') top center no-repeat;
    background-size: 100% 100%;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-style: italic;
    color: #FFFF;
    cursor: pointer;
}
.entrance:hover{
    background: url('../../../assets/biIndex/entrance.png') top center no-repeat;
}
.rightbar {
  flex: 1;
  width: 100%;
  // padding-left: 0.05rem;
}
.bgbox {
  position: fixed;
  z-index: 8;
  height: 100vh;
  width: 4.8rem;
  left: 0;
  background: linear-gradient(-90deg, rgba(5, 25, 61, 0) 0%, #05193d 60%);
}
.hovers {
  background: linear-gradient(-90deg, rgba(5, 25, 61, 0) 0%, #05193d 60%);
}
.nohovers {
  width: 0.1rem;
  background: none;
}
.bibox {
  transition: left 0.5s;
  position: fixed;
  z-index: 9;
  top: 57px;
  left: 0;
  height: calc(100vh - 74px);
  text-align: center;
  color: let(--title-text-color);
  display: flex;
  justify-content: flex-end;
  // padding-top: 0.57rem;
}
.chart-box {
  width: 4.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chart-item {
  width: 100%;
  height: 36.8%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    0deg,
    rgba(8, 65, 100, 0.4),
    rgba(27, 46, 65, 0.4)
  );
  .chart-title {
    color: #fff;
    text-align: left;
    position: relative;
    height: 35px;
    p,
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    p {
      padding-left: 35px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-style: italic;
      line-height: 32px;
      text-shadow: 0px 3px 0px rgba(0, 9, 17, 0.52);
    }
  }
  .chart-footer {
    width: 100%;
    overflow: hidden;
  }
}
.pipe {
  height: 25%;
  padding-bottom: 0.1rem;
}
.pipe_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: 2.14rem;
}
.pipeBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 1.88rem;
  height: 0.88rem;
  border: 1px solid #19b2fd;
  background: rgba(0, 125, 222, 0.2);
  padding: 0 0 0 0.05rem;
  margin: 0.1rem 0rem 0 0.1rem;
  text-align: left;
  box-shadow: 0 0 0.05rem #19b2fd;
  .pipeBox_img {
    width: 0.5rem;
    height: 0.5rem;
    margin:0 0.02rem;
    .pipe_img {
      width: 100%;
      height: 100%;
    }
  }
  .pipeBox_number {
    font-size: 0.28rem;
    font-weight: bold;
    color: #ffffff;
    height: 0.4rem;
  }
  .pipeBox_text {
    font-size: 0.14rem;
    font-weight: 400;
    color: #5bd3ff;
  }
}
// .rLeft {
//   position: relative;
// }
.Putaway {
  background: #182c3e;
  position: absolute;
  top: calc((100% - 0.46rem) / 2);
  right: -0.1rem;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 0.15rem;
  justify-content: center;
  cursor: pointer;
  width: 0.1rem;
  z-index: 8;
  height: 0.46rem;
}
.titleContext__between {
  display: flex;
  justify-content: space-between;
}
.more-text__btn {
  padding-right: 25px;
  font-size: 14px;
  font-weight: 400;
  color: #73f7ff;
  line-height: 40px;
  font-style: normal;
  cursor: pointer;
}
.polling {
  padding: 0.1rem 0.1rem;
  height: 0.4rem;
  .polling_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .person_info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .person_name {
        font-size: 0.16rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #00f6ff;
      }
      .person_img {
        width: 0.09rem;
        height: 0.1rem;
        margin-left: 0.08rem;
      }
    }
    .person_num {
      font-size: 0.2rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
  }
  .schedule {
    height: 0.16rem;
    line-height: 0.16rem;
    border: 1px solid rgba(223, 254, 255, 0.15);
    .schedule_box {
      width: 3.62rem;
      height: 0.04rem;
      background: rgba(94, 167, 255, 0.3);
      border-radius: 0.02rem;
      margin: 0.06rem auto;
      position: relative;
      .schedule_img {
        position: absolute;
        top: -0.06rem;
        width: 0.2rem;
        transition-duration: 1s;
        height: 0.16rem;
      }
    }
    .schedule_fill {
      width: 1rem;
      height: 0.04rem;
      background: linear-gradient(
        90deg,
        rgba(236, 170, 29, 0.5) 50%,rgba(182, 144, 56, 0.85), #ECAA1D
      );
      transition-duration: 1s;
      border-radius: 2px;
    }
    .one{
      width: 0rem !important;
    }
    .two{
      left: -0.1rem !important;
    }
  }
}
.pp1 {
  overflow-y: auto;
  height: 2.9rem;
  position: relative;
  .slide-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // .slide-item {
    //   // width: 100%;
    //   height: 0.5rem;
    // }
  }
}
::-webkit-scrollbar {
  width: 0px;
}
</style>
